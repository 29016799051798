import React from "react";
import {NavLink} from "react-router-dom";

const MobilePanel = () => {


    return (
        <>
            <div className={"mMenu"}>
                <div style={{float: 'left'}} className={"element"}>
                    <NavLink to={"/"}>
                        <img className={"mLogo"} src={"https://arkadiadevelopment.pl/img/logo.jpeg"}
                             alt={""}/>
                    </NavLink>
                </div>
                <NavLink to={"/menu"} style={{float: 'right', marginTop: "3vh", marginRight: "2vw", color: "black", textDecoration: "none"}} className={"element mChoose"}>
                    <div className={"mChoose center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-card-text" viewBox="0 0 16 16">
                            <path
                                d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path
                                d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </div>
                </NavLink>
            </div>
        </>
    )
}

export default MobilePanel
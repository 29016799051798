import React from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";


const Partners = () => {

    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start","center")
    }

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <h1 className={"bottom-size center"}>Już wkrótce ...</h1>
                <Footer/>
            </>
        );
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <h1 className={"bottom-size center"}>Już wkrótce ...</h1>
                <Footer/>
            </>
        );
    }
}

export default Partners;

import React from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";
import {Carousel} from "react-responsive-carousel";

const Progress = () => {


    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start", "center")
    }

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <div className={"mBottom"} style={{marginTop: "1vh"}}>
                    <a href={"https://goo.gl/maps/JG1c69xdsEaFwEoa9"} style={{color: "black", textDecoration: "none"}}>
                        <h4 className={"center txtFont"} style={{fontSize: "larger", textAlign: "center"}}> ul.
                            Górnośląska 16 |
                            Legionowo - kliknij, żeby zobaczyć na mapie</h4>
                    </a>
                    <div className={"center"}>
                        <Carousel showThumbs={false} autoPlay={false} infiniteLoop={true} interval={4000}
                                  showArrows={true} showStatus={false} showIndicators={false} autoFocus={true}
                                  width={"80vw"} emulateTouch={true} swipeable={true} stopOnHover={false}>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome1.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome2.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome3.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome4.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome5.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome6.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome7.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                            <div>
                                <img src="https://arkadiadevelopment.pl/img/welcome8.webP" alt=""
                                     className="mRepresent-photo-large"/>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <Footer/>
            </>

        );
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <a href={"https://goo.gl/maps/JG1c69xdsEaFwEoa9"} style={{color: "black", textDecoration: "none"}}>
                    <h4 className={"center txtFont"} style={{fontSize: "x-large"}}> ul. Górnośląska 16 | Legionowo -
                        kliknij, żeby zobaczyć na mapie</h4>
                </a>
                <div className="bottom-size center">
                    <Carousel showThumbs={false} autoPlay={false} infiniteLoop={true} showArrows={true}
                              showStatus={false} showIndicators={false} autoFocus={true} width={"65vw"}
                              emulateTouch={true} swipeable={true} stopOnHover={false}>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome1.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome2.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome3.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome4.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome5.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome6.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome7.webP" alt=""
                                 className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome8.webP" alt=""
                                 className="represent-photo-large" style={{objectFit: "contain"}}/>
                        </div>
                    </Carousel>
                </div>
                <Footer/>
            </>

        );
    }
};

export default Progress;

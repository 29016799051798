import React from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {NavLink, useParams} from 'react-router-dom';
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";


const Plans = ({letter}) => {

    let values = [599,629,649,659,679,689,699]
    let cost
    let garden
    let right = false


    switch (letter) {
        case "A":
            cost = values[1]
            garden = 30
            right = true;
            break;
        case "B":
            cost = values[2]
            garden = 43
            break;
        case "C":
            cost = values[4]
            garden = 50
            right = true;
            break;
        case "D":
            cost = values[4]
            garden = 45
            break;
        case "E":
            cost = values[4]
            garden = 46
            right = true;
            break;
        case "F":
            cost = values[6]
            garden = 180
            break;
        case "G":
            cost = values[5]
            garden = 82
            right = true;
            break;
        case "H":
            cost = values[3]
            garden = 28
            break;
        case "I":
            cost = values[2]
            garden = 28
            right = true;
            break;
        case "J":
            cost = values[3]
            garden = 42
            break;
        case "K":
            cost = values[2]
            garden = 45
            right = true;
            break;
        case "L":
            cost = values[1]
            garden = 35
            break;
        default:
            cost = "Brak danych"
            garden = "Brak danych"
            break;
    }

    if (isMobile) {
        if (!right) {
            return (
                <>
                    <div>
                        <NavLink to={"/apartments/graniczna"} style={{float: 'left', marginLeft: "1vw"}}>
                            <button className={"round-button"}> {"<"} </button>
                        </NavLink>
                        <h2 className={"center"} style={{paddingTop: "1vh", paddingRight: "8vw"}}>{`Plany apartamentu ${letter}`}</h2>
                        <br/>
                        <h5 className={"center"} style={{paddingTop: "1vh"}}>Powierzchnia ogródka: {garden} m<sup>2</sup>,
                        </h5>
                        <h5 className={"center"} style={{paddingTop: "1vh"}}>Powierzchnia całkowita: 96,25 m<sup>2</sup>,
                        </h5>
                    </div>
                    <div style={{width: "100vw",}}>
                        <img src={"https://arkadiadevelopment.pl/img/parter lewa.jpg"} alt={""} style={{width: "100vw",paddingRight: "25vw"}}/>
                    </div>
                    <div style={{width: "100vw",}}>
                        <img src={"https://arkadiadevelopment.pl/img/piętro  lewa.jpg"} alt={""} style={{width: "100vw", paddingRight: "15vw"}}/>
                    </div>
                    <div style={{width: "100vw",}}>
                        <img src={"https://arkadiadevelopment.pl/img/poddasze lewa.jpg"} alt={""} style={{width: "100vw", paddingRight: "15vw"}}/>
                    </div>


                    <div style={{marginBottom: "8vh"}}>
                        <a className={"center"} href={"https://arkadiadevelopment.pl/img/Rzuty apartamentu2.pdf"} download>Pobierz
                            plik pdf<img src="https://img.icons8.com/office/30/000000/pdf.png" alt={""}/></a>
                    </div>
                </>
            )
        } else {

            return (
                <>
                    <div>
                        <NavLink to={"/apartments/graniczna"} style={{float: 'left', marginLeft: "1vw"}}>
                            <button className={"round-button"}> {"<"} </button>
                        </NavLink>
                        <h2 className={"center"} style={{paddingTop: "1vh", paddingRight: "8vw"}}>{`Plany apartamentu ${letter}`}</h2>
                        <br/>
                        <h5 className={"center"} style={{paddingTop: "1vh"}}>Powierzchnia ogródka: {garden} m<sup>2</sup>,
                        </h5>
                        <h5 className={"center"} style={{paddingTop: "1vh"}}>Powierzchnia całkowita: 96,25 m<sup>2</sup>,
                        </h5>
                    </div>
                    <div style={{width: "100vw",}}>
                        <img src={"https://arkadiadevelopment.pl/img/parter prawa.jpg"} alt={""} style={{width: "100vw",paddingLeft: "10vw"}}/>
                    </div>
                    <div style={{width: "100vw",}}>
                        <img src={"https://arkadiadevelopment.pl/img/piętro  prawa.jpg"} alt={""} style={{width: "100vw", paddingLeft: "15vw"}}/>
                    </div>
                    <div style={{width: "100vw",}}>
                        <img src={"https://arkadiadevelopment.pl/img/poddasze prawa.jpg"} alt={""} style={{width: "100vw", paddingLeft: "15vw"}}/>
                    </div>


                    <div style={{marginBottom: "8vh"}}>
                        <a className={"center"} href={"https://arkadiadevelopment.pl/img/Rzuty apartamentu1.pdf"} download>Pobierz
                            plik pdf<img src="https://img.icons8.com/office/30/000000/pdf.png" alt={""}/></a>
                    </div>
                </>
            )
        }
    } else {
        if (!right) {
            return (
                <>
                    <div style={{height: 60}}>
                        <NavLink to={"/apartments/graniczna"} style={{float: 'left', marginLeft: "1vw"}}>
                            <button className={"round-button"}> {"<"} </button>
                        </NavLink>
                        <h2 className={"center"} style={{paddingTop: "1vh"}}>{`Plany apartamentu ${letter}`}</h2>
                        <h5 className={"center"} style={{paddingTop: "1vh"}}>Powierzchnia ogródka: {garden} m<sup>2</sup>,
                            Powierzchnia całkowita: 96,25 m<sup>2</sup></h5>
                    </div>
                    <div className={"center"}
                         style={{width: '100vw', height: '63vh', paddingTop: '10vh', marginBottom: '3vh'}}>
                        <img src={"https://arkadiadevelopment.pl/img/parter lewa.jpg"} alt={""}
                             className={"rzut"}/>
                        <img src={"https://arkadiadevelopment.pl/img/piętro  lewa.jpg"} alt={""}
                             className={"rzut"}/>
                        <img src={"https://arkadiadevelopment.pl/img/poddasze lewa.jpg"} alt={""}
                             className={"rzut"}/>
                    </div>
                    <div style={{marginBottom: "8vh"}}>
                        <a className={"center"} href={"https://arkadiadevelopment.pl/img/Rzuty apartamentu2.pdf"} download>Pobierz
                            plik pdf<img src="https://img.icons8.com/office/30/000000/pdf.png" alt={""}/></a>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className={"bottom-size"}>
                        <div style={{height: 60}}>
                            <NavLink to={"/apartments/graniczna"} style={{float: 'left'}}>
                                <button className={"round-button"}> {"<"} </button>
                            </NavLink>
                            <h2 className={"center"} style={{paddingTop: '1vh'}}>{`Plany apartamentu ${letter}`}</h2>
                            <h5 className={"center"} style={{paddingTop: '1vh'}}>Powierzchnia ogródka: {garden} m<sup>2</sup>,
                                Powierzchnia całkowita: 96,25 m<sup>2</sup></h5>
                        </div>

                        <div className={"center"}
                             style={{width: '100vw', height: '63vh', paddingTop: '10vh', marginBottom: '3vh'}}>
                            <img src={"https://arkadiadevelopment.pl/img/parter prawa.jpg"} alt={""}
                                 className={"rzut"}/>
                            <img src={"https://arkadiadevelopment.pl/img/piętro  prawa.jpg"} alt={""}
                                 className={"rzut"}/>
                            <img src={"https://arkadiadevelopment.pl/img/poddasze prawa.jpg"} alt={""}
                                 className={"rzut"}/>
                        </div>
                        <div style={{marginBottom: "8vh"}}>
                            <a className={"center"} href={"https://arkadiadevelopment.pl/img/Rzuty apartamentu1.pdf"}
                               download>Pobierz plik pdf<img src="https://img.icons8.com/office/30/000000/pdf.png"
                                                             alt={""}/></a>
                        </div>
                    </div>
                </>
            )
        }
    }

}


const Flat = () => {

    const {letter} = useParams();


    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start", "center")
    }

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <Plans letter={letter}/>
                <Footer/>
            </>
        );
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <Plans letter={letter}/>
                <Footer/>
            </>
        );
    }
};

export default Flat;
async function getGeo() {

    try {
        const name = await fetch('https://ipapi.co/json/')
            .then((response) => response.json())

        document.getElementById("c").dataset.country = name.country


    } catch (error) {
        console.error(error);
    }
}


export {

    getGeo

}

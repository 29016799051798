import React, {useState} from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";

const Contact = () => {

    const [isOpen, setIsOpen] = useState(false);

    const FormPopUp = () => {
        setIsOpen(!isOpen);
    }

    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start", "center")
    }

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <div className="mContact center">
                    <img src={"https://arkadiadevelopment.pl/img/mobilePhone.gif"} alt={""} className={"mContact center"} style={{paddingLeft : "10vw"}}/>
                </div>
                {/*<div style={{width: "100vw", height: "2vh",paddingTop: "5vh"}} className={"center"} >*/}
                {/*    <button type={"button"}>Chcesz się z nami skontaktować?</button>*/}
                {/*</div>*/}
                {/*{isOpen ? <ContactForm/> : null}*/}
                <Footer/>
            </>
        );
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <div className="center">
                    <img src={"https://arkadiadevelopment.pl/img/largerPhone.gif"} alt={""}/>
                </div>
                {/*<div style={{width: "100vw", height: "2vh",paddingTop: "5vh"}} className={"center"} >*/}
                {/*    <button type={"button"}>Chcesz się z nami skontaktować?</button>*/}
                {/*</div>*/}
                {/*{isOpen ? <ContactForm/> : null}*/}
                <Footer/>
            </>
        );
    }
}

export default Contact;

import React from 'react';
import Panel from "./repeated/Panel";
import Footer from "./repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "./repeated/MobilePanel";
import {getGeo} from "./repeated/Geo";
import { useCookies } from 'react-cookie';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const BigImage = () => {

    const [cookies, setCookie] = useCookies(['language']);

    const reload = () => {
        getGeo().then(e => e);
        setCookie('language', document.getElementById("c").dataset.country,{expires: new Date(9999,12,31),sameSite: "lax",maxAge: Number.MAX_VALUE});

    }

    if (isMobile) {
        return (
            <>
                <div className="mBottom center" style={{paddingBottom: "15vh"}} onLoad={reload}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={4000} showArrows={false} showStatus={false} showIndicators={false} autoFocus={true} width={"80vw"} emulateTouch={false} swipeable={false} stopOnHover={false}>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome1.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome2.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome3.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome4.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome5.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome6.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome7.webP"  alt="" className="mRepresent-photo-large"/>
                        </div>
                    </Carousel>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="bottom-size center" style={{paddingTop: "9vh"}} onLoad={reload}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={4000} showArrows={false} showStatus={false} showIndicators={false} autoFocus={true} width={"65vw"} emulateTouch={false} swipeable={false} stopOnHover={false}>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome1.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome2.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome3.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome4.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome5.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome6.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome7.webP"  alt="" className="represent-photo-large"/>
                        </div>
                    </Carousel>
                </div>
            </>
        );
    }
}


const App = () => {

    document.getElementById("body").classList.remove("start", "center")

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <BigImage/>
                <Footer/>
            </>
        )
    } else {
        document.getElementById("body").style.overflow = "hidden"
        return (
            <>
                <Panel/>
                <BigImage/>
                <Footer/>
            </>
        );
    }


};

export default App;

import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {

    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start", "center")
    }

    return (
        <div style={{width: "100vw", height: "100vh"}} className={"center"}>
            <div className={"center"} style={{textSize: "medium"}}>
                <h1 style={{textAlign: "center"}}>Nie ma takiej strony
                <br/>
                    <Link to={"/"}>Kliknij, żeby powrócić na stronę główną</Link>
                </h1>
            </div>
        </div>
    );
};

export default NotFound;
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';
import App from "./js/App";
import Contact from "./js/constant/Contact";
import About from "./js/constant/About";
import Sale from "./js/constant/Sale";
import Progress from "./js/constant/Progress";
import Partners from "./js/constant/Partners";
import Graniczna from "./js/constant/Graniczna";
import Flat from "./js/constant/Flat";
import MoMenu from "./js/repeated/MoMenu";
import NotFound from "./js/constant/NotFound";
import {CookiesProvider} from "react-cookie";

const Index = () => {
    return (
        <BrowserRouter>
            <>
                <CookiesProvider>
                    <Routes>
                        <Route exact path='/' element={<App />}/>
                        <Route path='/contact' element={<Contact />}/>
                        <Route path='/about-us' element={<About />}/>
                        <Route path='/projects/for-sale/:r?' element={<Sale />}/>
                        <Route path='/projects/in-progress' element={<Progress />}/>
                        <Route path='/partners' element={<Partners />}/>
                        <Route path='/apartments/graniczna' element={<Graniczna />}/>
                        <Route path='/graniczna/lokale/:letter' element={<Flat />}/>
                        <Route path='/menu' element={<MoMenu/>}/>
                        <Route path={'*'} exact={true} element={<NotFound />}/>
                    </Routes>
                </CookiesProvider>
            </>
        </BrowserRouter>

    );
};

ReactDOM.render(
    <Index/>,
    document.getElementById('main')
);


import React from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {NavLink} from "react-router-dom";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";


const Graniczna = () => {

    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start","center")
    }

if (isMobile) {
    return (
        <>
            <MobilePanel/>
            <div>
            <div className={"center"}>
                <h3 className={"txtFont"} style={{fontSize: "large", marginTop: "2vh"}}>Apartamenty ul. Graniczna 6 | Legionowo</h3>
            </div>
            <div style={{paddingTop: '2vh'}}>
                <div className={"center"}>
                    <p className={"txtFont"} style={{fontSize: "medium", width: "90vw", textAlign: "center"}}>Kompleks apartamentów Graniczna 6 składa sie z:
                        dwóch budynków po
                        cztery segmenty oraz dwóch
                        po dwa segmenty - łącznie dwanaście apartamentów. Inwestycja zlokalizowana jest w bardzo dobrze
                        położonym miejscu Legionowa. W bezpośrednim sąsiedztwie niska zabudowa, szybki dojazd do
                        głównej drogi dojazdowej a zarazem cicha i spokojna okolica. W pobliżu sklepy, szkoły,
                        przedszkola oraz komunikacja miejska. Segmenty składają się z trzech kondygnacji o łącznej
                        powierzchni 96 m<sup>2</sup>. Do każdego segmentu należy miejsce parkingowe oraz
                        ogródek. <br/>
                        <br/>

                        <strong>W celu zapoznania się z rzutami danego segmentu, należy kliknąć na niego na mapie poniżej</strong><br/>
                        <br/>
                        <p>Legenda mapy: <br/>
                            Kolor <strong style={{color: "red"}}>czerwony</strong> - segment sprzedany <br/>
                            Kolor <strong style={{color: "blue"}}>niebieski</strong> - segment zarezerwowany <br/>
                            Kolor <strong style={{color: "gray"}}>szary</strong> - segment wolny
                        </p>
                    </p>

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                     id="Warstwa_2" x="0px" y="0px" viewBox="0 0 595.28 841.89"
                     style={{enableBackground: `new 0 0 595.28 841.89`,}}>
                    <g id="Warstwa_1_m">
                        <polygon className="st0"
                                 points="311.53,562.39 55.27,563.01 57.9,58.28 546.93,11.1 547.77,564.57 	"/>
                        <polygon className="st1" points="186.47,507.45 186.47,501.35 167.34,501.35 167.2,105.12 436.11,105.31 436.11,501.21 414.9,501.54
		414.9,507.92 	"/>
                        <rect x="118.2" y="443.76" className="st9" width="48.46" height="56.65"/>
                        <NavLink to="/graniczna/lokale/K">
                            <rect x="118.2" y="387.11" className="st2" width="48.46" height="56.65"/>
                        </NavLink>
                        <NavLink to="/graniczna/lokale/J">
                            <rect x="118.2" y="275.8" className="st2" width="48.46" height="56.65"/>
                        </NavLink>
                        <rect x="118.2" y="219.15" className="st9" width="48.46" height="56.65"/>
                        <rect x="118.2" y="162.5" className="st9" width="48.46" height="56.65"/>
                        <rect x="118.2" y="105.85" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="443.76" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="387.11" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="275.8" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="219.15" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="162.5" className="st10" width="48.46" height="56.65"/>
                        <rect x="436.61" y="105.85" className="st9" width="48.46" height="56.65"/>
                        <rect x="173.94" y="383.15" className="st1" width="12.44" height="54.1"/>
                        <line className="st3" x1="173.94" y1="382.79" x2="186.38" y2="437.25"/>
                        <line className="st4" x1="173.94" y1="436.82" x2="186.38" y2="382.79"/>
                        <rect x="173.94" y="446.18" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="173.94" y1="499.85" x2="186.38" y2="445.82"/>
                        <rect x="414.81" y="383.32" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="382.97" x2="427.24" y2="437.42"/>
                        <line className="st1" x1="414.81" y1="437" x2="427.24" y2="382.97"/>
                        <rect x="414.81" y="446" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="445.64" x2="427.24" y2="500.1"/>
                        <line className="st1" x1="414.81" y1="499.67" x2="427.24" y2="445.64"/>
                        <rect x="414.81" y="287.1" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="286.75" x2="427.24" y2="341.2"/>
                        <line className="st1" x1="414.81" y1="340.78" x2="427.24" y2="286.75"/>
                        <rect x="414.81" y="106.2" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="105.85" x2="427.24" y2="160.3"/>
                        <line className="st1" x1="414.81" y1="159.88" x2="427.24" y2="105.85"/>
                        <rect x="414.81" y="167.18" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="166.83" x2="427.24" y2="221.28"/>
                        <line className="st1" x1="414.81" y1="220.86" x2="427.24" y2="166.83"/>
                        <rect x="414.81" y="221.21" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="220.86" x2="427.24" y2="275.31"/>
                        <line className="st1" x1="414.81" y1="274.89" x2="427.24" y2="220.86"/>
                        <rect x="176.8" y="287.1" className="st1" width="12.44" height="54.1"/>
                        <line className="st3" x1="176.8" y1="286.75" x2="189.24" y2="341.2"/>
                        <line className="st3" x1="176.8" y1="340.78" x2="189.24" y2="286.75"/>
                        <rect x="176.8" y="106.2" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="176.8" y1="105.85" x2="189.24" y2="160.3"/>
                        <line className="st1" x1="176.8" y1="159.88" x2="189.24" y2="105.85"/>
                        <rect x="176.8" y="167.18" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="176.8" y1="166.83" x2="189.24" y2="221.28"/>
                        <line className="st1" x1="176.8" y1="220.86" x2="189.24" y2="166.83"/>
                        <rect x="176.8" y="221.21" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="176.8" y1="220.86" x2="189.24" y2="275.31"/>
                        <line className="st1" x1="176.8" y1="274.89" x2="189.24" y2="220.86"/>
                        <rect x="436.61" y="508.93" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="436.61" y1="508.58" x2="449.05" y2="563.03"/>
                        <line className="st1" x1="436.61" y1="562.61" x2="449.05" y2="508.58"/>
                        <path className="st3" d="M455.94,563.03"/>
                        <rect x="449.05" y="508.93" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="449.05" y1="508.58" x2="461.49" y2="563.03"/>
                        <line className="st1" x1="449.05" y1="562.61" x2="461.49" y2="508.58"/>
                        <path className="st3" d="M469.89,563.03"/>
                        <rect x="141.79" y="508.32" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="141.79" y1="507.96" x2="154.23" y2="562.42"/>
                        <line className="st1" x1="141.79" y1="561.99" x2="154.23" y2="507.96"/>
                        <path className="st3" d="M125.36,562.42"/>
                        <rect x="154.23" y="508.32" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="154.23" y1="507.96" x2="166.67" y2="562.42"/>
                        <line className="st1" x1="154.23" y1="561.99" x2="166.67" y2="507.96"/>
                        <path className="st3" d="M139.31,562.42"/>
                        <path className="st3" d="M166.67,519.74"/>
                        <line className="st1" x1="173.94" y1="445.82" x2="186.38" y2="500.28"/>
                        <polyline className="st3" points="166.67,497.33 166.67,500.67 166.67,500.83 	"/>
                        <path className="st3" d="M164.2,508.32"/>
                        <line className="st3" x1="0" y1="583.13" x2="595.28" y2="583.13"/>
                        <line className="st3" x1="0" y1="624.33" x2="595.28" y2="624.33"/>
                        <polyline className="st3" points="0,603.73 1.2,603.73 43.44,603.73 	"/>
                        <line className="st3" x1="58.04" y1="603.73" x2="100.28" y2="603.73"/>
                        <line className="st3" x1="113.74" y1="603.73" x2="155.98" y2="603.73"/>
                        <line className="st3" x1="168.73" y1="603.73" x2="210.97" y2="603.73"/>
                        <line className="st3" x1="225.09" y1="603.73" x2="267.33" y2="603.73"/>
                        <line className="st3" x1="281.6" y1="603.73" x2="323.83" y2="603.73"/>
                        <line className="st3" x1="335.79" y1="603.73" x2="378.02" y2="603.73"/>
                        <line className="st3" x1="389.88" y1="603.73" x2="432.12" y2="603.73"/>
                        <line className="st3" x1="445.02" y1="603.73" x2="487.25" y2="603.73"/>
                        <line className="st3" x1="498.83" y1="603.73" x2="541.06" y2="603.73"/>
                        <line className="st3" x1="553.04" y1="603.73" x2="595.28" y2="603.73"/>
                        <path className="st3" d="M59.5,72.9"/>
                        <path className="st5" d="M186.78,522.47c0.08,0,0,39.95,0,39.95h-20.11V522h20.11"/>
                        <polygon className="st5" points="436.92,523.18 414.81,523.12 414.56,563.03 436.61,563.03 	"/>
                        <path className="st3" d="M425.74,537.78"/>
                        <line className="st3" x1="166.69" y1="508.32" x2="166.67" y2="522"/>
                        <path className="st3" d="M184.5,508.32"/>
                        <path className="st3" d="M285.94,561.99"/>
                        <path className="st1" d="M167.25,508.32v13.06V508.32z"/>
                        <path className="st1" d="M167.25,509.57"/>
                        <path className="st1" d="M167.25,509.86"/>
                        <polyline className="st1" points="167.25,508.32 436.06,508.58 436.06,522.66 414.1,522.66 414.01,562.2 187.42,561.66 187.35,521.37
		167.99,521.37 167.45,508.72 	"/>
                        <rect x="284.65" y="633.91" className="st6" width="61.23" height="15.31"/>
                        <text transform="matrix(1 0 0 1 284.6545 642.4261)" className="st7 st8">Graniczna</text>
                        <rect x="453.79" y="294.07" className="st6" width="14.11" height="17.6"/>
                        <text transform="matrix(1 0 0 1 453.7893 308.978)" className="st7 ">6.B</text>
                        <rect x="452.96" y="238.85" className="st6" width="15.99" height="18.83"/>
                        <text transform="matrix(1 0 0 1 452.9567 253.7581)" className="st7 ">6.C</text>
                        <rect x="454.55" y="181.83" className="st6" width="14.4" height="18.66"/>
                        <text transform="matrix(1 0 0 1 454.5522 196.7421)" className="st7 ">6.D</text>
                        <rect x="455.19" y="125.01" className="st6" width="13.12" height="19.89"/>
                        <text transform="matrix(1 0 0 1 455.1899 139.9191)" className="st7 ">6.E</text>
                        <rect x="136.13" y="125.77" className="st6" width="18.24" height="20.27"/>
                        <text transform="matrix(1 0 0 1 136.1339 140.6753)" className="st7 ">6.F</text>
                        <rect x="136.64" y="181.65" className="st6" width="16.65" height="18.36"/>
                        <text transform="matrix(1 0 0 1 136.6396 196.5549)" className="st7 ">6.G</text>
                        <rect x="141.53" y="238.09" className="st6" width="6.87" height="19.57"/>
                        <text transform="matrix(1 0 0 1 141.5287 252.9967)" className="st7 " style={{textAlign: "center"}}>6.H</text>
                        <rect x="138.42" y="294.96" className="st6" width="9.41" height="18.32"/>
                        <NavLink to="/graniczna/lokale/J" style={{textDecoration: "none"}}>
                            <text transform="matrix(1 0 0 1 138.4214 309.8725)" className="st7 ">6.J</text>
                            <rect x="136.41" y="403.8" className="st6" width="15.06" height="21.94"/>
                        </NavLink>
                        <NavLink to="/graniczna/lokale/K" style={{textDecoration: "none"}}>
                            <text transform="matrix(1 0 0 1 136.4067 418.705)" className="st7 ">6.K</text>
                            <rect x="138" y="463.11" className="st6" width="13.6" height="17.39"/>
                        </NavLink>
                            <text transform="matrix(1 0 0 1 138.0024 478.0247)" className="st7 ">6.L</text>
                    </g>
                    <path className="st3" d="M414.81,501.54"/>
                    <rect x="454.45" y="462.28" className="st6" width="13.74" height="20.21"/>
                    <text transform="matrix(1 0 0 1 454.4468 477.1906)" className="st7 ">6</text>
                    <rect x="455.04" y="406.97" className="st6" width="11.61" height="16.93"/>
                    <text transform="matrix(1 0 0 1 455.0395 421.8821)" className="st7 ">6.A</text>
                    <polyline className="st3" points="485.08,500.41 519.66,500.24 547.77,500.1 "/>
                    <line className="st3" x1="485.08" y1="443.76" x2="547.59" y2="443.76"/>
                    <line className="st3" x1="436.11" y1="357.78" x2="547.77" y2="357.78"/>
                    <line className="st3" x1="485.08" y1="275.8" x2="547.33" y2="275.8"/>
                    <line className="st3" x1="485.08" y1="219.15" x2="547.33" y2="219.15"/>
                    <line className="st3" x1="485.08" y1="162.5" x2="547.77" y2="162.5"/>
                    <line className="st3" x1="309.61" y1="106.2" x2="309.61" y2="32.93"/>
                    <line className="st3" x1="118.2" y1="162.5" x2="57.36" y2="162.5"/>
                    <line className="st3" x1="118.2" y1="219.15" x2="57.36" y2="219.15"/>
                    <line className="st3" x1="118.2" y1="275.8" x2="56.77" y2="275.31"/>
                    <line className="st3" x1="167.29" y1="360.33" x2="56.33" y2="360.33"/>
                    <line className="st3" x1="118.2" y1="443.76" x2="56.33" y2="443.76"/>
                    <line className="st3" x1="118.2" y1="500.41" x2="55.27" y2="500.28"/>
                </svg>
                <div className={"center"}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.0670706052088!2d20.923470776961345!3d52.387334446042935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eb912dfb60991%3A0x5cfe6ab2a1b71202!2sApartamenty%20Graniczna%206!5e0!3m2!1spl!2spl!4v1693517124171!5m2!1spl!2spl" style={{border:0, width: "100vw", height: "31vh",}} allowFullScreen="true" loading="lazy" className={"center"} title="google maps mobile"/>
                </div>
            </div>
            </div>
            <Footer/>
        </>

    );
} else {

    if (document.getElementById("body").style.length === 0) {
        document.getElementById("body").style.overflow = "hidden"
    }

    return (
        <>
            <Panel/>
            <div className={"center"}>
                <h3 className={"txtFont"} style={{fontSize: "x-large"}}>Apartamenty ul. Graniczna 6 | Legionowo</h3>
            </div>
            <div className={"center"} style={{paddingTop: '2vh'}}>
                <div style={{width: '44vw', height: '30vh', paddingLeft: '4vw', paddingBottom: '22vh', paddingTop: '6vh'}} className={"center"}>
                    <p className={"txtFont"} style={{fontSize: "medium"}}>Kompleks apartamentów Graniczna 6 składa sie z:
                        dwóch budynków po
                        cztery segmenty oraz dwóch
                        po dwa segmenty - łącznie dwanaście apartamentów. Inwestycja zlokalizowana jest w bardzo dobrze
                        położonym miejscu Legionowa. W bezpośrednim sąsiedztwie niska zabudowa, szybki dojazd do
                        głównej drogi dojazdowej a zarazem cicha i spokojna okolica. W pobliżu sklepy, szkoły,
                        przedszkola oraz komunikacja miejska. Segmenty składają się z trzech kondygnacji o łącznej
                        powierzchni 96,25 m<sup>2</sup>. Do każdego segmentu należy miejsce parkingowe oraz
                        ogródek. <br/>
                        <br/>

                        <strong>W celu zapoznania się z rzutami danego segmentu, należy kliknąć na niego na mapie po
                            prawej
                            stronie.</strong><br/>
                        <br/>
                        <p>Legenda mapy: <br/>
                            Kolor <strong style={{color: "red"}}>czerwony</strong> - segment sprzedany <br/>
                            Kolor <strong style={{color: "blue"}}>niebieski</strong> - segment zarezerwowany <br/>
                            Kolor <strong style={{color: "gray"}}>szary</strong> - segment wolny
                        </p>
                    </p>

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                     id="Warstwa_2" x="0px" y="0px" viewBox="0 0 595.28 841.89"
                     style={{enableBackground: `new 0 0 595.28 841.89`, height: '85vh', width: '55vw'}}>
                    <g id="Warstwa_1">
                        <polygon className="st0"
                                 points="311.53,562.39 55.27,563.01 57.9,58.28 546.93,11.1 547.77,564.57 	"/>
                        <polygon className="st1" points="186.47,507.45 186.47,501.35 167.34,501.35 167.2,105.12 436.11,105.31 436.11,501.21 414.9,501.54
		414.9,507.92 	"/>
                        <rect x="118.2" y="443.76" className="st9" width="48.46" height="56.65"/>
                        <NavLink to="/graniczna/lokale/K">
                            <rect x="118.2" y="387.11" className="st2" width="48.46" height="56.65"/>
                        </NavLink>
                        <NavLink to="/graniczna/lokale/J">
                            <rect x="118.2" y="275.8" className="st2" width="48.46" height="56.65"/>
                        </NavLink>
                        <rect x="118.2" y="219.15" className="st9" width="48.46" height="56.65"/>
                        <rect x="118.2" y="162.5" className="st9" width="48.46" height="56.65"/>
                        <rect x="118.2" y="105.85" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="443.76" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="387.11" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="275.8" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="219.15" className="st9" width="48.46" height="56.65"/>
                        <rect x="436.61" y="162.5" className="st10" width="48.46" height="56.65"/>
                        <rect x="436.61" y="105.85" className="st9" width="48.46" height="56.65"/>
                        <rect x="173.94" y="383.15" className="st1" width="12.44" height="54.1"/>
                        <line className="st3" x1="173.94" y1="382.79" x2="186.38" y2="437.25"/>
                        <line className="st4" x1="173.94" y1="436.82" x2="186.38" y2="382.79"/>
                        <rect x="173.94" y="446.18" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="173.94" y1="499.85" x2="186.38" y2="445.82"/>
                        <rect x="414.81" y="383.32" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="382.97" x2="427.24" y2="437.42"/>
                        <line className="st1" x1="414.81" y1="437" x2="427.24" y2="382.97"/>
                        <rect x="414.81" y="446" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="445.64" x2="427.24" y2="500.1"/>
                        <line className="st1" x1="414.81" y1="499.67" x2="427.24" y2="445.64"/>
                        <rect x="414.81" y="287.1" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="286.75" x2="427.24" y2="341.2"/>
                        <line className="st1" x1="414.81" y1="340.78" x2="427.24" y2="286.75"/>
                        <rect x="414.81" y="106.2" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="105.85" x2="427.24" y2="160.3"/>
                        <line className="st1" x1="414.81" y1="159.88" x2="427.24" y2="105.85"/>
                        <rect x="414.81" y="167.18" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="166.83" x2="427.24" y2="221.28"/>
                        <line className="st1" x1="414.81" y1="220.86" x2="427.24" y2="166.83"/>
                        <rect x="414.81" y="221.21" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="414.81" y1="220.86" x2="427.24" y2="275.31"/>
                        <line className="st1" x1="414.81" y1="274.89" x2="427.24" y2="220.86"/>
                        <rect x="176.8" y="287.1" className="st1" width="12.44" height="54.1"/>
                        <line className="st3" x1="176.8" y1="286.75" x2="189.24" y2="341.2"/>
                        <line className="st3" x1="176.8" y1="340.78" x2="189.24" y2="286.75"/>
                        <rect x="176.8" y="106.2" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="176.8" y1="105.85" x2="189.24" y2="160.3"/>
                        <line className="st1" x1="176.8" y1="159.88" x2="189.24" y2="105.85"/>
                        <rect x="176.8" y="167.18" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="176.8" y1="166.83" x2="189.24" y2="221.28"/>
                        <line className="st1" x1="176.8" y1="220.86" x2="189.24" y2="166.83"/>
                        <rect x="176.8" y="221.21" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="176.8" y1="220.86" x2="189.24" y2="275.31"/>
                        <line className="st1" x1="176.8" y1="274.89" x2="189.24" y2="220.86"/>
                        <rect x="436.61" y="508.93" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="436.61" y1="508.58" x2="449.05" y2="563.03"/>
                        <line className="st1" x1="436.61" y1="562.61" x2="449.05" y2="508.58"/>
                        <path className="st3" d="M455.94,563.03"/>
                        <rect x="449.05" y="508.93" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="449.05" y1="508.58" x2="461.49" y2="563.03"/>
                        <line className="st1" x1="449.05" y1="562.61" x2="461.49" y2="508.58"/>
                        <path className="st3" d="M469.89,563.03"/>
                        <rect x="141.79" y="508.32" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="141.79" y1="507.96" x2="154.23" y2="562.42"/>
                        <line className="st1" x1="141.79" y1="561.99" x2="154.23" y2="507.96"/>
                        <path className="st3" d="M125.36,562.42"/>
                        <rect x="154.23" y="508.32" className="st1" width="12.44" height="54.1"/>
                        <line className="st1" x1="154.23" y1="507.96" x2="166.67" y2="562.42"/>
                        <line className="st1" x1="154.23" y1="561.99" x2="166.67" y2="507.96"/>
                        <path className="st3" d="M139.31,562.42"/>
                        <path className="st3" d="M166.67,519.74"/>
                        <line className="st1" x1="173.94" y1="445.82" x2="186.38" y2="500.28"/>
                        <polyline className="st3" points="166.67,497.33 166.67,500.67 166.67,500.83 	"/>
                        <path className="st3" d="M164.2,508.32"/>
                        <line className="st3" x1="0" y1="583.13" x2="595.28" y2="583.13"/>
                        <line className="st3" x1="0" y1="624.33" x2="595.28" y2="624.33"/>
                        <polyline className="st3" points="0,603.73 1.2,603.73 43.44,603.73 	"/>
                        <line className="st3" x1="58.04" y1="603.73" x2="100.28" y2="603.73"/>
                        <line className="st3" x1="113.74" y1="603.73" x2="155.98" y2="603.73"/>
                        <line className="st3" x1="168.73" y1="603.73" x2="210.97" y2="603.73"/>
                        <line className="st3" x1="225.09" y1="603.73" x2="267.33" y2="603.73"/>
                        <line className="st3" x1="281.6" y1="603.73" x2="323.83" y2="603.73"/>
                        <line className="st3" x1="335.79" y1="603.73" x2="378.02" y2="603.73"/>
                        <line className="st3" x1="389.88" y1="603.73" x2="432.12" y2="603.73"/>
                        <line className="st3" x1="445.02" y1="603.73" x2="487.25" y2="603.73"/>
                        <line className="st3" x1="498.83" y1="603.73" x2="541.06" y2="603.73"/>
                        <line className="st3" x1="553.04" y1="603.73" x2="595.28" y2="603.73"/>
                        <path className="st3" d="M59.5,72.9"/>
                        <path className="st5" d="M186.78,522.47c0.08,0,0,39.95,0,39.95h-20.11V522h20.11"/>
                        <polygon className="st5" points="436.92,523.18 414.81,523.12 414.56,563.03 436.61,563.03 	"/>
                        <path className="st3" d="M425.74,537.78"/>
                        <line className="st3" x1="166.69" y1="508.32" x2="166.67" y2="522"/>
                        <path className="st3" d="M184.5,508.32"/>
                        <path className="st3" d="M285.94,561.99"/>
                        <path className="st1" d="M167.25,508.32v13.06V508.32z"/>
                        <path className="st1" d="M167.25,509.57"/>
                        <path className="st1" d="M167.25,509.86"/>
                        <polyline className="st1" points="167.25,508.32 436.06,508.58 436.06,522.66 414.1,522.66 414.01,562.2 187.42,561.66 187.35,521.37
		167.99,521.37 167.45,508.72 	"/>
                        <rect x="284.65" y="633.91" className="st6" width="61.23" height="15.31"/>
                        <text transform="matrix(1 0 0 1 284.6545 642.4261)" className="st7 st8">Graniczna</text>
                        <rect x="453.79" y="294.07" className="st6" width="14.11" height="17.6"/>
                            <text transform="matrix(1 0 0 1 453.7893 308.978)" className="st7">6.A</text>
                            <rect x="452.96" y="238.85" className="st6" width="15.99" height="18.83"/>
                            <text transform="matrix(1 0 0 1 452.9567 253.7581)" className="st7">6.C</text>
                            <rect x="454.55" y="181.83" className="st6" width="14.4" height="18.66"/>
                            <text transform="matrix(1 0 0 1 454.5522 196.7421)" className="st7">6.D</text>
                            <rect x="455.19" y="125.01" className="st6" width="13.12" height="19.89"/>
                            <text transform="matrix(1 0 0 1 455.1899 139.9191)" className="st7 ">6.E</text>
                            <rect x="136.13" y="125.77" className="st6" width="18.24" height="20.27"/>
                            <text transform="matrix(1 0 0 1 136.1339 140.6753)" className="st7 ">6.F</text>
                            <rect x="136.64" y="181.65" className="st6" width="16.65" height="18.36"/>
                            <text transform="matrix(1 0 0 1 136.6396 196.5549)" className="st7 ">6.G</text>
                            <rect x="141.53" y="238.09" className="st6" width="6.87" height="19.57"/>
                            <text transform="matrix(1 0 0 1 141.5287 252.9967)" className="st7 ">6.H</text>
                            <rect x="138.42" y="294.96" className="st6" width="9.41" height="18.32"/>
                        <NavLink to="/graniczna/lokale/J" style={{textDecoration: "none"}}>
                            <text transform="matrix(1 0 0 1 138.4214 309.8725)" className="st7 ">6.J</text>
                            <rect x="136.41" y="403.8" className="st6" width="15.06" height="21.94"/>
                        </NavLink>
                        <NavLink to="/graniczna/lokale/K" style={{textDecoration: "none"}}>
                            <text transform="matrix(1 0 0 1 136.4067 418.705)" className="st7 ">6.K</text>
                            <rect x="138" y="463.11" className="st6" width="13.6" height="17.39"/>
                        </NavLink>
                            <text transform="matrix(1 0 0 1 138.0024 478.0247)" className="st7 ">6.L</text>
                    </g>
                    <path className="st3" d="M414.81,501.54"/>
                    <rect x="454.45" y="462.28" className="st6" width="13.74" height="20.21"/>
                        <text transform="matrix(1 0 0 1 454.4468 477.1906)" className="st7 ">6</text>
                        <rect x="455.04" y="406.97" className="st6" width="11.61" height="16.93"/>
                        <text transform="matrix(1 0 0 1 455.0395 421.8821)" className="st7 ">6.A</text>
                        <polyline className="st3" points="485.08,500.41 519.66,500.24 547.77,500.1 "/>
                    <line className="st3" x1="485.08" y1="443.76" x2="547.59" y2="443.76"/>
                    <line className="st3" x1="436.11" y1="357.78" x2="547.77" y2="357.78"/>
                    <line className="st3" x1="485.08" y1="275.8" x2="547.33" y2="275.8"/>
                    <line className="st3" x1="485.08" y1="219.15" x2="547.33" y2="219.15"/>
                    <line className="st3" x1="485.08" y1="162.5" x2="547.77" y2="162.5"/>
                    <line className="st3" x1="309.61" y1="106.2" x2="309.61" y2="32.93"/>
                    <line className="st3" x1="118.2" y1="162.5" x2="57.36" y2="162.5"/>
                    <line className="st3" x1="118.2" y1="219.15" x2="57.36" y2="219.15"/>
                    <line className="st3" x1="118.2" y1="275.8" x2="56.77" y2="275.31"/>
                    <line className="st3" x1="167.29" y1="360.33" x2="56.33" y2="360.33"/>
                    <line className="st3" x1="118.2" y1="443.76" x2="56.33" y2="443.76"/>
                    <line className="st3" x1="118.2" y1="500.41" x2="55.27" y2="500.28"/>
                </svg>
                <div style={{width: "20vw", height: "55vh"}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.0670706052088!2d20.923470776961345!3d52.387334446042935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eb912dfb60991%3A0x5cfe6ab2a1b71202!2sApartamenty%20Graniczna%206!5e0!3m2!1spl!2spl!4v1693517124171!5m2!1spl!2spl" style={{border:0, width: "18vw", height: "30vh", paddingRight: "2vw"}} allowFullScreen="true" loading="lazy" className={"center"} title={"google maps stable"}/>
                </div>
            </div>
            <Footer/>
        </>

    );
}

}

export default Graniczna;
